import React,{ useEffect } from 'react';
import './App.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import ReactGA from 'react-ga4';
import Home from './components/Home/Home';
import Terms from './components/Terms/Terms';
import Policy from './components/Policy/Policy';
import Layout from './components/Layout/Layout';

function uuidv4() {
  return "10000000-1000-4000-8000-100000000000".replace(/[018]/g, (c:any) =>
    (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
  );
}

function App() {
  useEffect(() => {
    let existingUUID = window.localStorage.getItem('analytics_uuidv4');
    if(!existingUUID) {
      existingUUID =  uuidv4();
      window.localStorage.setItem('analytics_uuidv4', existingUUID);
    }
    ReactGA.initialize('G-60QY7Z5HX4', {
      gaOptions: {
        userId: existingUUID,
      }
    });
  } ,[]);

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="/terms" element={<Terms />} />
          <Route path="/policy" element={<Policy />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
